import { css } from '@emotion/core'
import { format } from 'date-fns'
import { graphql } from 'gatsby'
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer'
import React from 'react'
import { Subscribe } from 'unstated'

import pink from '@material-ui/core/colors/pink'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import ShareIcon from '@material-ui/icons/Share'
import TwitterIcon from 'mdi-material-ui/Twitter'

import UpdatesContainer from '../containers/UpdatesContainer'

import MaterialMDXProvider from '../material-mdx-provider'
import { isSSR } from '../util'
import withRoot from '../withRoot'
import Layout from './layout'
import NewsletterForm from './newsletter'

import { convertTokens, legacyParse } from "@date-fns/upgrade/v2";

const Share = ({ title }) => {
  if (!isSSR && navigator.share) {
    return (
      <IconButton
        onClick={() =>
          navigator.share({
            title,
            text: `Check out the Reach FI app`,
            url: `${window.location.origin}${window.location.pathname}`,
          })
        }
      >
        <ShareIcon />
      </IconButton>
    )
  }

  return null
}

const postStyles = css`
  padding: 24px;
  margin-bottom: 16px;

  code {
    font-family: Consolas, Menlo, 'Lucida Console', 'Courier New', monospace;
    color: ${pink[900]};
  }
`

const UpdatesLayout = ({ data: { mdx, mdxSortedByDate } }) => (
  <Layout
    title={`${mdx.frontmatter.title}`}
    pathname={mdx.fields.slug}
    SEOProps={{ description: mdx.excerpt }}
    upwards="/updates"
    skipOnboarding
    appBarContent={
      <>
        <Share title={mdx.frontmatter.title} />
        <IconButton
          component="a"
          href="https://twitter.com/reachfiapp"
          title="Connect with @reachfiapp on Twitter"
        >
          <TwitterIcon />
        </IconButton>
      </>
    }
  >
    <Subscribe to={[UpdatesContainer]}>
      {updates => {
        const latestUpdate = mdxSortedByDate.edges[0].node.id
        if (updates.state.lastUpdateSeen !== latestUpdate) {
          updates.setLastUpdateSeen(latestUpdate)
        }
      }}
    </Subscribe>
    <Paper css={postStyles}>
      <Typography variant="overline" gutterBottom>
        posted {format(legacyParse(mdx.frontmatter.date), convertTokens('MMM DD, YYYY'))}
      </Typography>
      <MaterialMDXProvider>
        <MDXRenderer>{mdx.body}</MDXRenderer>
      </MaterialMDXProvider>
    </Paper>

    <NewsletterForm />
  </Layout>
)

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      excerpt
      frontmatter {
        title
        date
      }
      fields {
        slug
      }
    }

    mdxSortedByDate: allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          id
        }
      }
    }
  }
`

export default withRoot(UpdatesLayout)
